import axios from 'axios';
export const SEND_CONTACTFORM = 'SEND_CONTACTFORM';




export const sendcontactform = (data) => {
    return (dispatch) => {
        return axios.post('https://mamtiazaza.fr/sendEmail.php', data, {headers: {'Content-Type': 'multipart/form-data'}}).then((res) => {
            dispatch({ type: SEND_CONTACTFORM, payload: res.data });
        });
    }
}