import React from 'react';

const Aboutus01 = () => {
    return (
    <div className="aboutus">
        <div className="titre">
            <em><p>"Bien grandir, naturellement."</p></em>
        </div>
        <div className="vide"></div>
    </div>
    )
}
export default Aboutus01;