import React from 'react';

const Bannerhome01 = () => {
    return (
        <div className="banner01">
            <img src="./images/Select/bird2.gif" alt="oiseau qui vole" className="mybird"/>
            <p><span>Découvrez</span> la sincère affection et l'attachement profond que nous pouvons éprouver envers les enfants.</p>
            <img src="./images/Select/logo.png" alt="logo MAM"/>
        </div>
    );
};

export default Bannerhome01;