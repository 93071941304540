import { combineReducers } from "redux";
import modalReducer from "./modal.reducer";
import { contactformReducer } from "./contactform.reducer";
import { inscriptionReducer } from "./incription.reducer";



export default combineReducers({
    modalReducer,
    contactformReducer, 
    inscriptionReducer,
});