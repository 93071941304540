import React, { useEffect, useState } from "react";
import Navigation from "../components/Navigation";
import Mainimage from "../components/Mainimage";
import Mainimage02 from "../components/Mainimage02";
import Bannerhome01 from "../components/Bannerhome01";
import Bannerhome02 from "../components/Bannerhome02";
import Bannerhome03 from "../components/Bannerhome03";
import Modalfooter from "../components/Modalfooter";
import Footer from "../components/Footer";

const Home = () => {
  const [oldy, setOldy] = useState(window.scrollY);
  const [menustate, setMenustate] = useState(true); 
  const hideorshowmenu = () => {
    setOldy(window.scrollY);
    if (oldy < window.scrollY) {
      setMenustate(false);
    } else {
      setMenustate(true);
    }
    // console.log('oldY : ' + oldy + ' && newY :' + window.scrollY);
  } 
  useEffect(() => {
    window.addEventListener('scroll', hideorshowmenu);
    return () => {
      window.removeEventListener('scroll', hideorshowmenu);
    }
   }, [window.scrollY]);

  return (
    <div >
      <Navigation menustate={menustate} />
      <Mainimage />
      <Mainimage02 />
      <Bannerhome01 />
      <Bannerhome02 />
      <Bannerhome03 />
      <Modalfooter />
      <Footer />
    </div>
  );
};

export default Home;
