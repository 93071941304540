import React from "react";

const Bannerhome02 = () => {
    return (
        <section className="normalcontainer">
            <div className="bandeau01">
                <div id="ourteam">
                    <div className="imagepart02">
                    <img src="./images/New/Fondatrices.jpg" alt="Claudie et Yen"/> 
                    <h3 className="">Claudie DAFFE et Yen DECOTTIGNIES</h3>
                    </div>
                </div>
                <div className="textpart">
                    <h2>Les fondatrices </h2>
                    <p>Nous sommes ravies de vous accueillir dans notre communauté dévouée d'assistantes maternelles agréées. Chez nous, votre enfant trouvera un environnement chaleureux, sûr et stimulant pour grandir et s'épanouir.
                    <br /><br />
                    Notre engagement envers le bien-être de chaque enfant est notre priorité absolue. 
                    Nous sommes impatientes de vous accueillir dans notre communauté chaleureuse et attentionnée.
                    Rejoignez notre famille de parents et d'enfants, et découvrez la tranquillité d'esprit que nous pouvons vous offrir.</p></div>
                </div>

        </section>
    )
}

export default Bannerhome02;