import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { modalHandler } from '../action/modal.action';


const Aboutus05 = () => {
    const dispatch = useDispatch();
    const [imagebdd, setImagebdd] = useState([
        {
            imgsrc: './images/New/Ruban.jpg',
            id: 0,
            position: 0,
            event: 'Inauguration - 03 Mai 2024',
        },
        {
            imgsrc: './images/New/Ouverture.jpg',
            id: 1,
            position: 1,
            event: 'Inauguration - 03 Mai 2024',
        },
        {
            imgsrc: './images/New/Welcome.jpg',
            id: 2,
            position: 2,
            event: 'Inauguration - 03 Mai 2024',
        },
        {
            imgsrc: './images/New/Foire.jpg',
            id: 3,
            position: 3,
            event: 'Foire à Balma - 2023',
        },
        {
            imgsrc: './images/New/Prop.jpg',
            id: 4,
            position: 4,
            event: 'Inauguration - 03 Mai 2024',
        }
    ]);

    const [imgindex, setImgindex] = useState(1);
    const indexup = () => {    
        setImgindex((imagebdd.length + imgindex + 1) % imagebdd.length);
        console.log(imgindex);
        
    }
    const indexdown = () => {
        setImgindex((imagebdd.length + imgindex - 1) % imagebdd.length);
        console.log(imgindex);
    }

    useEffect(() => {
        // afficherimage();
        const imginterval = setInterval(() => {
            pushup();
        }, 5000);
        return () => {
            clearInterval(imginterval);
        }

    }, [imagebdd]);

    const [listimage, setListimage] = useState([]);
    const [newlist, setNewlist] = useState([]);
    const afficherimage = () => {
        const templist = imagebdd && imagebdd.map((image) => (
            <div>
                <img key={image.id} className={(image.id == imgindex) ? 'centermenow' : ''} src={image.imgsrc} alt={image.imgsrc} />
            </div>
        ));
        setListimage(templist);
        
    }
    const showdiapos = () => {
        const templist = imagebdd && imagebdd.filter((image) => (
            Number(image.position) > 0 && Number(image.position) < 4
        )).sort((a, b) => (a.position - b.position))
            .map((image, index) => (
            <div className={"diapogrid0"+ index} key={image.id}>
                <img key={image.id} src={image.imgsrc} alt={image.imgsrc} />
                <h4>{image.event}</h4>
            </div>
        ));
        setNewlist(templist);
        
    }

    const pushup = () => {
        // const newBdd = [];
        const newBdd = imagebdd;
        for (let i = 0; i < imagebdd.length; i++){
            newBdd[i].position = (imagebdd.length + newBdd[i].position + 1) % imagebdd.length;
            // newBdd[i] = imagebdd[(imagebdd.length + i + 1) % imagebdd.length];
        }
        // console.log(newBdd);
        setImagebdd(newBdd);
        showdiapos();
    }
    const pushdown = () => {
        // const newBdd = [];
        const newBdd = imagebdd;
        for (let i = 0; i < imagebdd.length; i++){
            newBdd[i].position = (imagebdd.length + newBdd[i].position - 1) % imagebdd.length;
            // newBdd[i] = imagebdd[(imagebdd.length + i + -1) % imagebdd.length];
        }
        setImagebdd(newBdd);
        showdiapos();
    }

    const getmodal = (command) => {
        dispatch(modalHandler(command));
    }

    return (
        <div className="banner02">
                <h2>Evènements</h2>
            <div className="imagegallery">
                <div className="gallerycontainer">
                    <div className="leftchevron"><button onClick={() => pushdown()}><i className="fa fa-chevron-left"></i></button></div>
                    <div className="imagecontainer"><div className="slidecontainer">{newlist}</div></div>
                    <div className="rightchevron">
                        {/* <button onClick={() => indexup()}><i className="fa fa-chevron-right"></i></button> */}
                        <button onClick={() => pushup()}><i className="fa fa-chevron-right"></i></button>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Aboutus05;