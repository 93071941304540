import React from 'react';
export const Aboutus03 = () => {
    return (
        <div id="svgContainer" className="svg-container">
            <svg id="svgPath1">
                <path d="" fill="transparent" strokeWidth="8" id="path1" />
            </svg>
            
            <svg id="svgPath2">
                <path d="" fill="transparent" strokeWidth="8" id="path2" />
            </svg>
            
            <svg id="svgPath3">
                <path d="" fill="transparent" strokeWidth="8" id="path3" />
            </svg>
            
            <svg id="svgPath4">
                <path d="" fill="transparent" strokeWidth="8" id="path4" />
            </svg>
            <svg id="svgPath5">
                <path d="" fill="transparent" strokeWidth="8" id="path5" />
            </svg>
        </div>
    )
}

export const Aboutus04 = () => {
    return (
        <div className="mamservices" id="mamservices">
            <div className="photo1" id="photo1container">
                <img src="./images/Select/alimentation.jpg" alt="nours"  id="photo1"/>
            </div>
            <div className="text1">
                <h2>L'alimentation</h2>
                <p>
                    Une alimentation saine et équilibrée, respectant l'âge et la maturité des enfants. Nous ferons appel à un prestataire de qualité, de la région, et qui n'utilise que des produits locaux et saisons et de nature biologique.
                </p>
            </div>
        
        
            <div className="text2">
                <h2>Nos activités</h2>
                <p>
                    Notre projet pédagogique cherche à développer l'épanouissement et l'autonomie de l'enfant, principalement à travers le jeu et la motricité libre. 
                    Diverses activités sont proposées quotidiennement pour favoriser le bon développement de l'enfant, son épanouissement et les interactions sociales. Afin de proposer des expériences nouvelles et variées aux enfants accueillis, nous établissons de nombreux partenariats. Des ateliers et sorties pédagogiques sont ainsi mis en place tout au long de l'année.
                </p>
            </div>
            <div className="photo2" id="photo2container">
                <img src="./images/Select/act3.jpg" alt="nours"  id="photo2"/>
            </div>
        
            <div className="photo3" id="photo3container">
                <img src="./images/Select/local4.jpg" alt="nours" id="photo3"/>
            </div>
            <div className="text3">
                <h2>Le local</h2>
                <p>
                   Une maison individuelle à proximité des grands axes et des transports, qui possède 5 places de parking, un parking vélo/moto.
                    Elle comprend principalement d'une zone d'accueil, une grande pièce principale ( salle d'éveil ) 4 chambres, un grand jardin clos et sécurisé, aménagé pour favoriser la motricité et le jeu libre.
                </p>
            </div>
            
            <div className="text4">
                <h2>Soins et sécurité</h2>
                <p>
                    Pour préserver la santé et la sécurité des enfants, nous nous équipons de tout le nécessaire. La MAM est aménagée selon les besoins des enfants, tout est adapté à leur âge, les jouets, les meubles, les sanitaires....
                </p>
            </div>
            <div className="photo4" id="photo4container">
                <img src="./images/Select/bebe.jpg" alt="nours" id="photo4"/>
            </div>
            
            <div className="photo5" id="photo5container">
                <img src="./images/Select/parents.jpg" alt="nours" id="photo5"/>
            </div>
            <div className="text5">
                <h2>La place des parents</h2>
                <p>
                    Les parents premiers éducateurs de leurs enfants, sont encouragés à participer activement à la vie de la MAM. Ils contribuent à la réalisation du projet d'accueil, prennent part aux ateliers, sorties ou fêtes. Toutes les familles sont accueillies à la MAM, nous créons avec elles un lien de confiance et de respect mutuel.
                </p>
            </div>
            <div className="text6">
                <h2>Le projet environnemental</h2>
                <p>
                    La M.A.M. s'inscrit dans une logique de développement durable. En accord avec la politique de la ville, nous mettons en place des actions concrètes afin de réduire notre empreinte écologique : tri des déchets, compostage, création d'un petit potager, sensibilisation des parents. <br/>
                    Nous favorisons une alimentation à base de produits locaux et de saisons, la plupart d'origine biologique.
                </p>
            </div>
            <div className="photo6" id="photo6container">
                <img src="./images/Select/garden.jpg" alt="nours" id="photo6"/>
            </div>
        </div>
    )
}