import React, { useEffect, useState } from "react";
import Navigation from "../components/Navigation";
import Modalfooter from "../components/Modalfooter";
import Footer from "../components/Footer";
import Contactform from "../components/Contactform";

const Contact = () => {
  const [oldy, setOldy] = useState(window.scrollY);
  const [menustate, setMenustate] = useState(true); 
  const hideorshowmenu = () => {
    setOldy(window.scrollY);
    if (oldy < window.scrollY) {
      setMenustate(false);
    } else {
      setMenustate(true);
    }
    // console.log('oldY : ' + oldy + ' && newY :' + window.scrollY);
  } 
  useEffect(() => {
    window.addEventListener('scroll', hideorshowmenu);
    return () => {
      window.removeEventListener('scroll', hideorshowmenu);
    }
   }, [window.scrollY]);
  return (
    <div>
      <Navigation menustate={menustate}  />
      <Contactform />
      <Modalfooter />
      <Footer />
    </div>
  )
}
export default Contact;