import React, { useEffect, useState } from "react";
import Navigation from "../components/Navigation";
import Modalfooter from "../components/Modalfooter";
import Footer from "../components/Footer";
import Aboutus01 from "../components/Aboutus01";
import Aboutus02 from "../components/Aboutus02";
import { Aboutus03, Aboutus04 } from "../components/Aboutusoth";
import Aboutus05 from "../components/Aboutus05";

const About = () => {
  const [oldy, setOldy] = useState(window.scrollY);
  const [menustate, setMenustate] = useState(true); 
  const hideorshowmenu = () => {
    setOldy(window.scrollY);
    if (oldy < window.scrollY) {
      setMenustate(false);
    } else {
      setMenustate(true);
    }
    // console.log('oldY : ' + oldy + ' && newY :' + window.scrollY);
  } 
  useEffect(() => {
    window.addEventListener('scroll', hideorshowmenu);
    return () => {
      window.removeEventListener('scroll', hideorshowmenu);
    }
   }, [window.scrollY]);
  return (
    <div>
      <Navigation menustate={menustate} />
      <Aboutus01 />
      <Aboutus02 />
      <Aboutus03 />
      <Aboutus04 />
      <Aboutus05 />
      <Modalfooter />
      <Footer />
    </div>
  )
}

export default About;