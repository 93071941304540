import React, { useEffect, useState } from 'react';
import { NavLink } from "react-router-dom"

const Navigation = ({menustate}) => {

    return (
        <div className={"menu"} id="menu" style={{position: menustate ? 'fixed' : 'relative', top: menustate ? '0px' : 0 }} >
            <nav>
                <div className="logo">
                    <NavLink to="/"><img src="./images/Select/logo.png" alt="" /></NavLink>
                </div>
                <ul>
                    <li>
                        <NavLink to="/" className={(nav) => (nav.isActive ? "highlight" : "")}>Accueil</NavLink>
                    </li>
                    <li>
                        <NavLink to="/aboutus" className={(nav) => (nav.isActive) ? "highlight" : ""}>A propos de nous </NavLink>
                    </li>
                    <li>
                        <NavLink to="/contact" className={(nav) => (nav.isActive ? "highlight" : "")}>Contact</NavLink>
                    </li>
                </ul>
                <div id='mobilemenu'>
                    <li>
                        <NavLink to="/" className={(nav) => (nav.isActive ? "highlight" : "")}>Accueil</NavLink>
                    </li>
                    <li>
                        <NavLink to="/aboutus" className={(nav) => (nav.isActive) ? "highlight" : ""}>A propos de nous </NavLink>
                    </li>
                    <li>
                        <NavLink to="/contact" className={(nav) => (nav.isActive ? "highlight" : "")}>Contact</NavLink>
                    </li>
                </div>
            </nav>
        </div>
    );
};

export default Navigation;