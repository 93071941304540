import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';


// import {}
import { Provider, useSelector } from "react-redux";
import { configureStore } from '@reduxjs/toolkit';
import rootReducer from './reducers';

import "./style/index.scss";
import "./style/font-awesome4/css/font-awesome.css";
import "./style/font-awesome4/css/font-awesome.min.css";
import "./style/font-awesome5/css/all.css";
import "./style/font-awesome5/css/all.min.css";

const store = configureStore({
  reducer: rootReducer,
  devTools: true,
})

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  
  <Provider store={store}>
    <App />
  </Provider>
);


