import axios from "axios";

export const INSCRIPTION = 'INSCRIPTION';



export const preinscription = (data) => {
    return (dispatch) => {
        return axios.post('https://mamtiazaza.fr/inscription.php', data, { headers: { 'Content-Type': 'multipart/form-data' } }).then((res) => {
            dispatch({ type: INSCRIPTION, payload: res.data });
        });
    }
}