import React, { useRef, useState } from 'react';
import { useSelector } from 'react-redux'; 
import { useDispatch } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { modalHandler } from '../action/modal.action';
import { preinscription } from '../action/inscription.action';


const Modalfooter = () => {
    const dispatch = useDispatch();
    const getmodal = (e) => {
        const data = {
            top: 0,
            modalstatus: false
        }
        if (e.target.className == "modal" || e.target.id == 'closeme') {
            dispatch(modalHandler(data));
            document.body.style.overflow = 'auto';
        }
    }
    const showmymodal = useSelector((state) => state.modalReducer);

    
    const preinscriptref = useRef();
    const [presubmitted, setPresubmitted] = useState(false);
    const preinscript = useSelector((state) => state.inscriptionReducer);

    const submitpreinscript = (e) => {
        e.preventDefault();
        const data = {
            'nom' : preinscriptref.current[0].value ,
            'age' : preinscriptref.current[1].value ,
            'unite' : preinscriptref.current[2].value ,
            'dateaccouchement' : preinscriptref.current[3].value ,
            'datedebut' : preinscriptref.current[4].value ,
            'heuresemaine' : preinscriptref.current[5].value ,
            'lundiarr' : preinscriptref.current[6].value ,
            'lundidep' : preinscriptref.current[7].value ,
            'mardiarr' : preinscriptref.current[8].value ,
            'mardidep' : preinscriptref.current[9].value ,
            'mercrediarr' : preinscriptref.current[10].value ,
            'mercredidep' : preinscriptref.current[11].value ,
            'jeudiarr' : preinscriptref.current[12].value ,
            'jeudidep' : preinscriptref.current[13].value ,
            'vendrediarr' : preinscriptref.current[14].value ,
            'vendredidep' : preinscriptref.current[15].value ,
            'nomdupere' : preinscriptref.current[16].value ,
            'teldupere' : preinscriptref.current[17].value ,
            'maildupere' : preinscriptref.current[18].value ,
            'profdupere' : preinscriptref.current[19].value ,
            'nomdemere' : preinscriptref.current[20].value ,
            'teldemere' : preinscriptref.current[21].value ,
            'maildemere' : preinscriptref.current[22].value ,
            'profdemere' : preinscriptref.current[23].value ,
        }
        if (data.nom && data.age && data.datedebut && data.heuresemaine && data.nomdemere && data.teldemere && data.profdemere) {
            dispatch(preinscription(data));
            setPresubmitted(false);
            preinscriptref.current.reset();
            if (preinscript) {
                alert(preinscript.message);
            }
        } else { 
            setPresubmitted(true);
        }
    }



    return (
        <div className="modal" style={{ display: showmymodal.modal && "flex", top: showmymodal.top}} id="mymodal"  onClick={(e) => getmodal(e)} >
            <div className="modal-content">
                {/* <!-- <span className="close span-right" id="close">&times;</span> --> */}
                <div className="modal-header" >
                    <div className="entete">
                        <h3>Fiche de pré-inscription - MAM Tiazaza</h3>
                    </div> 
                </div>
                <div className="modal-body">
                    <div className="bodycontainer">
                        <div className="homeform">
                            <form ref={preinscriptref} onSubmit={(e) => submitpreinscript(e)}>
                                <span style={{textDecoration: 'underline'}}>A propos de l'enfant : </span><br/><br/>
                                {/* <!-- // Nom --> */}
                                <span>Nom de l'enfant : </span> *
                                <input type="text" name="nom" id="childname" className=""/><br/>
                                {presubmitted && <div style={{fontSize: "12px", color: 'red', marginTop: '0', marginBottom: '10px'}}>*Veuillez remplir par le nom de l'enfant</div>}

                                {/* <!-- // Age --> */}
                                <span>Age : </span>*
                                <input type="number" name="age" id="select" min="0"/>
                                {presubmitted && <div style={{fontSize: "12px", color: 'red', marginTop: '0', marginBottom: '10px'}}>*Veuillez renseigner l'âge de l'enfant</div>}

                                {/* <!-- // unite --> */}
                                <select name="unite" id="">
                                    <option value="mois">mois</option>
                                    <option value="an">an</option>
                                </select><br/>
                                <div id="childageerror" className=""></div>

                                {/* <!-- // Date accouchement --> */}
                                <span>Date Prévue d'Accouchement (si l'enfant n'est pas encore né) : </span>
                                <input type="date" name="dateaccouchement" id="topinput02"/><br/><br/>


                                <span style={{textDecoration: 'underline'}}>Planning prévisionnel (à titre informatif) : </span><br/><br/>

                                
                                {/* <!-- // date debut accueil --> */}
                                <span>Date de début d'accueil souhaitée : </span>* 
                                <input type="date" name="datedebut" id="datedebutacc" className=""/> <br/>
                                {presubmitted && <div style={{fontSize: "12px", color: 'red', marginTop: '0', marginBottom: '10px'}}>*Veuillez renseigner la date prévue de début de l'accueil de votre enfant</div>}

                                {/* <!-- // Heure par semaine --> */}
                                <span>Nombre d'heures d'accueil prévues par semaine : </span>*
                                <input type="number" name="heuresemaine" id="heureparsemaine" min="10" className="topinput"/> 
                                <span>heures/semaine.</span><br/>
                                {presubmitted && <div style={{fontSize: "12px", color: 'red', marginTop: '0', marginBottom: '10px'}}>*Veuillez reenseigner le volume d'heures prévisionnel d'accueil souhaité</div>}

                            

                                <div className="mygrid">
                                    
                                    <span className="monjour">Jour</span>
                                    <span className="monarrivee">Heure d'arrivée</span>
                                    <span className="mondepart">Heure de départ</span>
                                                                
                                    <span className="lundi">Lundi</span><br/>
                                    <span className="mardi">Mardi</span><br/>
                                    <span className="mercredi">Mercredi</span><br/>
                                    <span className="jeudi">Jeudi</span><br/>
                                    <span className="vendredi">Vendredi</span><br/>
                                
                                    {/* <!-- // Lundi --> */}
                                    <span className="hideme01">de </span><input type="time" name="lundiarr" id="lundiarrivee"/>
                                    <span className="hideme02">à </span><input type="time" name="lundidep" id="lundidepart"/>

                                    {/* <!-- // Mardi --> */}
                                    <span className="hideme03">de </span><input type="time" name="mardiarr" id="mardiarrivee"/>
                                    <span className="hideme04">à </span><input type="time" name="mardidep" id="mardidepart"/>

                                    {/* <!-- // Mercredi --> */}
                                    <span className="hideme05">de </span><input type="time" name="mercrediarr" id="mercrediarrivee"/>
                                    <span className="hideme06">à </span><input type="time" name="mercredidep" id="mercredidepart"/>

                                    {/* <!-- // Jeudi --> */}
                                    <span className="hideme07">de </span><input type="time" name="jeudiarr" id="jeudiarrivee"/>
                                    <span className="hideme08">à </span><input type="time" name="jeudidep" id="jeudidepart"/>

                                    {/* <!-- // Vendredi --> */}
                                    <span className="hideme09">de </span><input type="time" name="vendrediarr" id="vendrediarrivee"/>
                                    <span className="hideme10">à </span><input type="time" name="vendredidep" id="vendredidepart"/>

                                    <span className="vide01"></span>
                                    <span className="vide02"></span>
                                    <span className="vide03"></span>
                                    <span className="vide04"></span>
                                    <span className="vide05"></span>
                                    
                                </div>

                                <br/><br/>

                                <span style={{textDecoration: 'underline'}}>Coordonnées des parents : </span><br/>
                                <span style={{textDecoration: 'underline', display: 'block', marginTop: '20px', fontWeight: 'bold'}}>Père</span>

                                <div className="myfather">  

                                    <span className="dadname">Nom : </span>
                                    <span className="dadtel">Tél : </span>
                                    <span className="dadmail">Email : </span>
                                    <span className="dadprof">Profession : </span>

                                    {/* <!-- // Father  --> */}
                                    <input type="text" name="nomdupere" id="nomdupere"/>
                                    <input type="text" name="teldupere" id="teldupere"/>
                                    <input type="text" name="maildupere" id="maildupere"/>
                                    <input type="text" name="profdupere" id="profdupere"/>
                                </div>

                                <span style={{textDecoration: 'underline', display: 'block', marginTop: '20px', fontWeight: 'bold'}}>Mère</span>
                                <div className="mymother">
                                    <span className="momname">Nom : *</span>
                                    <span className="momtel">Tél : *</span>
                                    <span className="mommail">Email : *</span>
                                    <span className="momprof">Profession : *</span>

                                    {/* <!-- // Mother --> */}
                                    <input type="text" name="nomdemere" id="nomdemere"/>
                                    {presubmitted && <div id="nomdemereerror" style={{fontSize: "12px", color: 'red', marginTop: '0', marginBottom: '10px'}}>*Veuillez renseigner le nom de la mère de l'enfant</div>}

                                    <input type="text" name="teldemere" id="teldemere"/>
                                    {presubmitted && <div id="teldemereerror" style={{fontSize: "12px", color: 'red', marginTop: '0', marginBottom: '10px'}}>*Veuillez renseigner un numéro de téléphone</div>}

                                    <input type="text" name="maildemere" id="maildemere"/>
                                    {presubmitted && <div id="maildemereerror" style={{fontSize: "12px", color: 'red', marginTop: '0', marginBottom: '10px'}}>*Veuillez renseigner une adresse email </div>}

                                    <input type="text" name="profdemere" id="profdemere"/>
                                    {presubmitted && <div id="profdemereerror" style={{fontSize: "12px", color: 'red', marginTop: '0', marginBottom: '10px'}}>*Veuillez renseigner la profession de la mère </div>}
                                </div>

                            
                            
                                <input type="submit" id="validateform"/>
                            </form>
                        </div>
                    </div>

                </div>
                <div className="modal-footer">
                    <button id="closeme" onClick={(e) => getmodal(e)}>Fermer</button>
                </div>
            </div>
        </div>
)
}

export default Modalfooter;