import { SHOW_MODAL } from "../action/modal.action";

const initialState = {
    top: 0,
    modal: false,
    
}

export default function modalReducer(state = initialState, action) {
    switch (action.type) {
        case SHOW_MODAL:
            return {
                top: action.payload.top,
                modal: action.payload.modalstatus,
            }
        default:
            return state;
    }
}