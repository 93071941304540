import React from 'react';
import { useDispatch } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { modalHandler } from '../action/modal.action';


const Footer = () => {
    const dispatch = useDispatch();
    
    const getmodal = (e, command) => {
        document.body.style.overflow = 'hidden';
        const modaltop = (Number(e.pageY) - Number(e.clientY) + 'px');
        const data = {
            top: modaltop,
            modalstatus: command,
        }
        dispatch(modalHandler(data));
        
    }
    return (
        <div className="mamfooter">
            <div className="footercontainer">
                    <div className="mapform"> 
                        <h3>Nous trouver</h3>
                        <div className="googlemap">
                            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1928.1352188142384!2d1.512325115358315!3d43.607819944187135!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x12aebd677a6fc3e7%3A0x1c996cc1c0ab0553!2s101%20Av.%20des%20Mourlingues%2C%2031130%20Balma%2C%20France!5e0!3m2!1sfr!2smg!4v1700145910337!5m2!1sfr!2smg" width="600" height="400" style={{border:'0' }} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
                        </div>   
                    </div>
    
                <div className="footcol02">
                    <h3>Liens utiles</h3>
                    <ul>
                        <li><a href="/aboutus">A propos de Tiazaza</a></li>
                        <li><a onClick={(e) => getmodal(e, true)} id="preadmission">Formulaire de pré-admission</a></li>
                        <li><a href="/contact">Nous contacter</a></li>
                        <li><a href="https://www.facebook.com/mamtiazaza/"><i className="fab fa-facebook display-1 fontawe"></i></a></li>
                    </ul>
                </div>
                <div className="footcol03">
                    <h3>Nos coordonnées</h3>
                    <ul>
                        <li><b>Adresse</b> : 103 Avenue des Mourlingues 31130 Balma</li>
                        <li><b>Horaires</b> : 
                            <ul>
                                <li>Lundi - Vendredi : 7h30 - 19h00</li>
                                <li>Fermé le week-end et les jours fériés</li>
                            </ul>
                        </li>
                        <li><b>Mail</b> : mamtiazaza[at]gmail.com</li>
                        <li><b>Téléphone</b> : 07 81 25 56 40 / 06 15 56 46 61</li>
                    </ul>
    
                </div>
    
            </div>
        </div>
    
        )
}
export default Footer;