import { SEND_CONTACTFORM } from "../action/contactform.action";

const initialState = {}


export const contactformReducer = (state = initialState, action) => {
    switch (action.type) {
        case SEND_CONTACTFORM:
            return action.payload;
        default: 
            return state;
    }
} 