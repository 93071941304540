import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { modalHandler } from '../action/modal.action';


const Bannerhome03 = () => {
    const dispatch = useDispatch();
    const [imagebdd, setImagebdd] = useState([
        {
            imgsrc: './images/New/Couverture.jpg',
            id: 0,
            position: 0,
        },
        {
            imgsrc: './images/New/Couverture03.jpg',
            id: 1,
            position: 1,
        },
        {
            imgsrc: './images/New/Couverture02.jpg',
            id: 2,
            position: 2,
        },
        {
            imgsrc: './images/New/Tapis.jpg',
            id: 3,
            position: 3,
        },
        {
            imgsrc: './images/New/Lavabo.jpg',
            id: 4,
            position: 4,
        },
        {
            imgsrc: './images/New/Toilettes.jpg',
            id: 5,
            position: 5,
        },
        {
            imgsrc: './images/New/Soins.jpg',
            id: 6,
            position: 6,
        },
        {
            imgsrc: './images/New/Couloir.jpg',
            id: 7,
            position: 7,
        },
    ]);

    const [imgindex, setImgindex] = useState(1);
    const indexup = () => {    
        setImgindex((imagebdd.length + imgindex + 1) % imagebdd.length);
        console.log(imgindex);
        
    }
    const indexdown = () => {
        setImgindex((imagebdd.length + imgindex - 1) % imagebdd.length);
        console.log(imgindex);
    }

    useEffect(() => {
        afficherimage();
        const imginterval = setInterval(() => {
            pushup();
        }, 5000);
        return () => {
            clearInterval(imginterval);
        }

    }, [imagebdd]);

    const [listimage, setListimage] = useState([]);
    const [newlist, setNewlist] = useState([]);
    const afficherimage = () => {
        const templist = imagebdd && imagebdd.map((image) => (
            <img key={image.id} className={(image.id == imgindex) ? 'centermenow' : ''} src={image.imgsrc} alt={image.imgsrc} />
        ));
        setListimage(templist);
        
    }
    const showdiapos = () => {
        const templist = imagebdd && imagebdd
            .filter((image) => (Number(image.position) > 3 && Number(image.position) < 7 ))
            .sort((a, b) => (a.position - b.position))
            .map((image, index) => (
            <div className={"diapogrid0"+ index} key={image.id}>
                <img key={image.id} src={image.imgsrc} alt={image.imgsrc} />
            </div>
        ));
        setNewlist(templist);
        
    }

    const pushup = () => {
        // const newBdd = [];
        const newBdd = imagebdd;
        for (let i = 0; i < imagebdd.length; i++){
            newBdd[i].position = (newBdd[i].position + 1) % imagebdd.length;
            // newBdd[i] = imagebdd[(imagebdd.length + i + 1) % imagebdd.length];
        }
        // console.log(newBdd);
        setImagebdd(newBdd);
        showdiapos();
    }
    const pushdown = () => {
        // const newBdd = [];
        const newBdd = imagebdd;
        for (let i = 0; i < imagebdd.length; i++){
            newBdd[i].position = (imagebdd.length + newBdd[i].position - 1) % imagebdd.length;
            // newBdd[i] = imagebdd[(imagebdd.length + i + -1) % imagebdd.length];
        }
        setImagebdd(newBdd);
        showdiapos();
    }

    const getmodal = (e, command) => {
        document.body.style.overflow = 'hidden';
        const modaltop = (Number(e.pageY) - Number(e.clientY) + 'px');
        const data = {
            top: modaltop,
            modalstatus: command,
        }
        dispatch(modalHandler(data));
        
    }

    return (
        <div className="banner02">
                <h2>Gallerie photo</h2>
            <div className="imagegallery">
                <div className="gallerycontainer">
                    <div className="leftchevron"><button onClick={() => pushdown()}><i className="fa fa-chevron-left"></i></button></div>
                    <div className="imagecontainer"><div className="slidecontainer">{newlist}</div></div>
                    <div className="rightchevron">
                        {/* <button onClick={() => indexup()}><i className="fa fa-chevron-right"></i></button> */}
                        <button onClick={() => pushup()}><i className="fa fa-chevron-right"></i></button>
                    </div>
                </div>
            </div>
            <div className="preinscription">
                <h2>Pré-inscription</h2>
                <p>
                    Nous serions ravies de vous accueillir à la Maison d'Assistants Maternels Tiazaza et de prendre soin de votre précieux petit bout. Pour entamer le processus, nous vous prions de bien vouloir remplir la fiche de pré-inscription disponible sur le lien ci-dessous.
                    <br/>
                    Une fois que nous aurons examiné les disponibilités, nous vous contacterons pour convenir d'un premier rendez-vous. Nous sommes impatients de faire votre connaissance et de discuter des détails pour assurer le meilleur environnement possible pour votre enfant au sein de notre établissement. 
                </p>
                <button id="modalbutton" onClick={(e, ) => getmodal(e, true)}>Remplir la fiche</button>
            </div>



        </div>
    )
}
export default Bannerhome03;